import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";

const Page = styled.div`
  & .text-container {
    max-width: 820px;
  }

  & h1,
  & strong {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }

  & p {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;

    &:first-of-type {
      margin-top: 0;
    }

    &:empty {
      display: flex;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }
`;

const TermsAndConditions = ({ data }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#ECE5DF`,
    });
  }, []);

  return (
    <>
      <PageSEO
        title={data.prismicTermsAndConditions.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicTermsAndConditions.url}`}
      />

      <Page>
        <div className="text-container">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: data.prismicTermsAndConditions.data.title.html,
            }}
          />
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicTermsAndConditions.data.text.html,
            }}
          />
        </div>
      </Page>
    </>
  );
};

export default withPreview(TermsAndConditions);

export const query = graphql`
  {
    prismicTermsAndConditions {
      url
      data {
        title {
          html
          text
        }
        text {
          html
        }
      }
    }
  }
`;
